<template>
  <p>Page Settings Admin ChannelSubscription</p>
</template>


<script>
export default {
  name: 'PageSettingsAdminChannelSubscription'
}
</script>
